// @flow
import React from 'react'
import styled from 'styled-components'
import Layout from 'containers/Layout'
import { useRedirect } from 'modules/user'
import {navigate} from 'theme/atoms/Link'

export default () => {
  const crumbs = [{ label: 'Startseite', link: '/' }, { label: 'Auth0 Callback' }]

  const redirect = useRedirect()

  React.useEffect(() => {
    redirect.auth0Redirect()
  }, [redirect.auth0Redirect])

  React.useEffect(() => {
    const id = setTimeout(() => {
      navigate('/')
    }, 1000 * 3)

    return () => clearTimeout(id)
  }, [])

  return (
    <Layout partialStateUpdates={false} breadcrumbs={crumbs}>
      <Redirect>Du wirst weitergeleitet...</Redirect>
    </Layout>
  )
}

const Redirect = styled.div`
  text-align: center;
  margin: 100px 0;
`
